import React, { useState } from "react";
import { useForm } from "react-hook-form";

const Contact = () => {
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [submitted, setSubmitted] = useState(false)

  const onSubmit = (data, e) => {
    e.preventDefault();
    let myForm = document.getElementById("contactForm");
    let formData = new FormData(myForm);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        console.log("Form successfully submitted")
        setSubmitted(_ => true)
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <form id="contactForm" className="contct-form" onSubmit={handleSubmit(onSubmit)} name="contact" data-netlify="true" data-netlify-recaptcha="true">
      <input {...register("form-name", { required: true })} type="hidden" name="form-name" value="contact" />
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Full name"
                {...register("name", { required: true })}
              />
              <label className="form-label">Nom - Prénom</label>
              {errors.name && errors.name.type === "required" && (
                <span className="invalid-feedback">Name is required</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-md-6">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Email address"
                {...register(
                  "email",
                  {
                    required: "Email is Required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Cette adresse mail n'est pas valide",
                    },
                  },
                  { required: true }
                )}
              />
              <label className="form-label">Email</label>
              {errors.email && (
                <span className="invalid-feedback">{errors.email.message}</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Subject"
                {...register("subject", { required: true })}
              />
              <label className="form-label">Sujet</label>
              {errors.subject && (
                <span className="invalid-feedback"> Un sujet est requis.</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <div className="form-group">
              <textarea
                rows="4"
                className="form-control"
                placeholder="Type comment"
                {...register("comment", { required: true })}
              ></textarea>
              <label className="form-label">Commentaire</label>
              {errors.comment && (
                <span className="invalid-feedback">Le commentaire est vide.</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
          <div data-netlify-recaptcha="true"></div>
            <div className="btn-bar">
              { submitted || <button id="sendForm" className="px-btn px-btn-theme">Envoyez votre message</button> }
              <div style={{'display': submitted ? 'inherit': 'none'}} > Merci pour votre message ! </div>
            </div>
          </div>
          {/* End .col-12 */}
        </div>
      </form>
    </>
  );
};

export default Contact;
