import React, { useState } from "react";
import Modal from "react-modal";
import Contact from "../../components/contact/Contact";
import ConcreteBlog from "./ConcreteBlog"
import { StaticQuery, graphql } from 'gatsby'

Modal.setAppElement("#___gatsby");

const Blog = ({count}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);

  function toggleModalOne() {
    window.location.replace('/blog');
  }
  function toggleModalTwo() {
    window.location.replace('/blog');
  }
  function toggleModalThree() {
    window.location.replace('/blog');
  }

  return <StaticQuery 
    query={graphql`
        query {
          allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            nodes {
              frontmatter {
                date(formatString: "DD MMMM YYYY", locale: "fr")
                title
                description
                cover {
                  childImageSharp {
                    fluid(maxWidth: 900) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
    `} 
    render={
      data => (<ConcreteBlog data={{data}} count={count} />)
    }
  />

};

export default Blog;