import React, { useState } from "react";
import Modal from "react-modal";
import Contact from "../../components/contact/Contact";
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'
import slugify from 'slugify'
import { Helmet } from "react-helmet";

Modal.setAppElement("#___gatsby");

const ConcreteBlog = ({ data, count }) => {

    const posts = data.data.allMarkdownRemark.nodes.slice(0, count || 3)

    return (<>
        
        <div className="row">
            {posts.map(({ frontmatter }) => (
                <div className="col-lg-4 col-md-6 my-3">
                    <Helmet>
                        <title>Yohanna Seychelles | {frontmatter.title} </title>
                    </Helmet>
                    <div className="blog-post" onClick={() => window.location.replace('/'+slugify(frontmatter.title))}>
                        <div className="blog-img">
                            <div className="data">
                                <small>{frontmatter.date}</small>
                            </div>
                            <img src={frontmatter.cover?.childImageSharp.fluid.src} style={{height:'250px', objectFit:'cover'}} alt="blog post"></img> 
                            {/*<Img fluid={frontmatter.cover?.childImageSharp.fluid} style={{ height: '250px' }} /> */}
                        </div>
                        <div className="blog-info">
                            <h6>{frontmatter.title}</h6>
                            <p>
                                {frontmatter.description}
                            </p>
                            <a className="btn-bar" href={'/'+slugify(frontmatter.title)}>
                                Lire l'article
                            </a>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </>)
};

export default ConcreteBlog;

