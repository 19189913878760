import React from "react";
import Contact from "./contact/Contact";

export default function ContactAndInfo() {
  return (
    <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 my-3">
              <div className="contct-form">
                <div className="sm-title">
                  <h4 className="font-alt">Contactez-moi</h4>
                  <p>
                    Sentez vous libre de me contacter pour plus d'informations. </p>
                </div>
                {/* End .sm-title */}
                <Contact />
              </div>
            </div>
           
    <div className="col-lg-4 ml-auto my-3">
            <div className="contact-info">
                <div className="sm-title" style={{'margin-bottom':'0px'}}>
                  <h4 className="font-alt">Infos pratiques</h4>
                  <p>
                    Les consultations se font uniquement sur rendez-vous. </p>
                  <p>
                    Les séances d'Aromathérapie en visioconférence s'adressent également aux personnes francophones habitant d'autres pays.
                  </p>
                </div>

                <div className="btn-bar my-5">
                  <a className="px-btn px-btn-theme" href="https://calendly.com/ysey-contact" target="_blank">
                    Prendre rendez-vous
                  </a>
                </div>
                <div className="media">
                  <div className="icon">
                    <i className="ti-map"></i>
                  </div>
                  <span className="media-body">
                    3 rue Joseph Lacarre<br/>
                    Centre ville de Petite île<br/>
                    97429.<br/>
                    À 2mn à pied de l'arrêt de bus "Église de Petite-Ile"
                  </span>
                </div>
                {/* End media */}

                <div className="media">
                  <div className="icon">
                    <i className="ti-email"></i>
                  </div>
                  <span className="media-body">
                    ysey.contact@gmail.com
                  </span>
                </div>
                {/* End media */}

                <div className="media">
                  <div className="icon">
                    <i className="ti-mobile"></i>
                  </div>
                  <span className="media-body">
                    +262 692 510 998
                  </span>
                </div>
                
                {/* End media */}
              </div>
            </div>
            </div>
            </div>
  );
}
