import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaPinterestP,
} from "react-icons/fa";

const SocialShare = [
  // { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  // { Social: <FaTwitter />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/ys_therapie/?igshid=YmMyMTA2M2Y=" },
  // { Social: <FaLinkedinIn />, link: "https://twitter.com/" },
  // { Social: <FaPinterestP />, link: "https://www.pinterest.com/" },
];

const Footer = () => {
  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-1 my-2">
          <div className="nav justify-content-center text-center text-md-left justify-content-md-start">
            {SocialShare.map((val, i) => (
              <a key={i} href={`${val.link}`} rel="noreferrer" target="_blank">
                {val.Social}
              </a>
            ))}
          </div>
          {/* End .nav */}
        </div>
        {/* End .col */}

        <div className="col-md-6" style={{'color':'white', 'fontSize':'14px'}}>
              <div>
              Ce site a été financé à l'aide du FEDER (REACT EU) dans le cadre de la réponse de l'Union européenne à la pandémie COVID-19. L'Europe s'engage à la Réunion.
              </div>
            
            <div style={{'marginTop':'10px'}}>
                <img src="/europe.png" style={{'width':'35px'}} />
                <img src="/region.webp" style={{'width':'45px','background':'white','marginLeft':'10px'}} /> 
            </div>
        </div>

        <div className="col-md-4 my-2 text-center text-md-right flex">
          <p>
            © {new Date().getFullYear()} Yohanna Seychelles - <a href="/mentions"> Mentions légales </a>
          </p>
        </div>
        {/* End .col */}
      </div>
      {/* End .row */}
    </>
  );
};

export default Footer;
